/* src/assets/css/supportBubble.css */

.support-bubble {
    position: fixed;
    bottom: 30px;
    left: 30px;
    background: linear-gradient(90deg, #142c82, #142c82, #ec0928);
    color: white;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-position 0.5s ease, transform 0.5s ease;
    background-size: 200% 200%;
    animation: gradientShift 4s ease infinite;
  }
  
  @keyframes gradientShift {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .support-bubble:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  
  @media (max-width: 768px) {
    .support-bubble {
      width: 60px;
      height: 60px;
      font-size: 24px;
      bottom: 15px;
      left: 15px;
    }
  }

  /* src/assets/css/supportChat.css */

.support-chat-body {
    max-height: 450px;
    min-height: 450px;
    overflow-y: auto;

  }
  
  .message {
    padding: 8px 12px;
    margin-bottom: 8px;
    max-width: 80%;
  }
  
  .message.user {
    background-color: #f2f2f2;
    color: black;
    align-self: flex-end;
    margin-left: auto;
    border-radius: 0.7rem;
    transition: background-color 0.1s ease-in-out, transform 0.2s ease-in-out;
  }
  
  .message.support {
    background-color: #ecbe54e9;
    color: black;
    align-self: flex-start;
    margin-right: auto;
    border-radius: 0.7rem;
    transition: background-color 0.1s ease-in-out, transform 0.2s ease-in-out;
  }
  
  .chat-avatar {
    width: 40px;
    height: 40px;
    margin: 0 10px;
  }
  
  .message-bubble {
    max-width: 60%;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    border-radius: 15px;
    background-color: #f1f1f1;
    position: relative;
    font-size: 14px;
  }
  
  .message-bubble.user {
    color:#000000;
    background-color: #bcbcbc;
    text-align: left;
  }
  
  .message-bubble.assistant {
    background-color: #142c82;
    text-align: start;
    color: #fffcfc;
  }
  
  .loader {
    width: 60px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side, #142c82 90%, #ec092700);
    background:
      var(--_g) 0% 50%,
      var(--_g) 50% 50%,
      var(--_g) 100% 50%;
    background-size: calc(100%/3) 50%;
    animation: l3 1s infinite linear;
  }

  @keyframes l3 {
    20% {
      background-position: 0% 0%, 50% 50%, 100% 50%
    }
  
    40% {
      background-position: 0% 100%, 50% 0%, 100% 50%
    }
  
    60% {
      background-position: 0% 50%, 50% 100%, 100% 0%
    }
  
    80% {
      background-position: 0% 50%, 50% 50%, 100% 100%
    }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }