/* src/assets/css/CustomTable.css */

/* Existing Table Styles */

.custom-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-family: Arial, sans-serif;
    background-color: white;
    /* Ensures the table has a white background */
}

.custom-table th,
.custom-table td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    background-color: white;
    /* Default background for all cells */
    opacity: 1;
    /* Ensure full opacity */
}

.custom-table th {
    background-color: #f2f2f2;
    cursor: pointer;
    user-select: none;
}

.custom-table tr:nth-child(even) td {
    background-color: #f9f9f9;
    /* Light gray background for even rows */
    opacity: 1;
    /* Ensure full opacity */
}

.custom-table tr:nth-child(odd) td {
    background-color: white;
    /* White background for odd rows */
    opacity: 1;
    /* Ensure full opacity */
}

.custom-table tr:hover td {
    background-color: #e0e0e0;
    /* Slightly darker on hover */
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.pagination button {
    border: 1px solid #dddddd;
    padding: 8px 12px;
    margin: 0 4px;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination button:hover:not(:disabled) {
    background-color: #ec0928;
}

.pagination button.active {
    background-color: #ec0928;
    color: white;
    border: 1px solid #ec0928;
}

.pagination button:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
}

.pagination span {
    margin: 0 8px;
}

/* Search and Filter Styles */
.search-filter-container {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 4vh;
    margin-bottom: 2vh;

}

.custom-title {
    flex: 1;

}

.search-input {
    padding: 8px 12px;
    width: 100%;
    max-width: 300px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    font-size: 16px;
}

.search-input:focus {
    outline: none;
    border-color: #ec0928;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

.filter-container {
    margin-left: 20px;
}

/* CustomTable.css */
.actions-column {
    width: 50px; 
    text-align: center;
  }
  
  /* Initially hide the button using opacity */
  .actions-column button {
    opacity: 0;
    transition: opacity 0.2s ease-in-out; /* Adjust the duration as needed */
  }
  
  /* Show the button when the row is hovered */
  tr:hover .actions-column button {
    opacity: 1;
  }