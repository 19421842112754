/* src/assets/css/Breadcrumbs.css */

.breadcrumb {
  background-color: transparent;
  align-items:center;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: #ffffff;
  
}

.breadcrumb-item a {
  color: #ffffff; /* Ajusta el color según tu diseño */
  text-decoration: none;
  font-size: 14px;
}

.breadcrumb-item.active {
  color: #ffffff; /* Ajusta el color según tu diseño */
  font-size: 14px;
  text-decoration: none;

}