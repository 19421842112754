.details-card {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
  }
  
  .details-card p {
    font-size: 18px;
    margin: 10px 0;
  }
  
  @media (max-width: 600px) {
    .details-card {
      padding: 15px;
    }
  
    .details-card p {
      font-size: 16px;
    }
  }

  .details-container {
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
    padding: 20px;
    border-radius: 8px;
  }
  
  .left-column,
  .right-column {
    padding: 15px;
  }
  
  .left-column {
    background-color: #f7f7f7; /* Light gray background */
    border-radius: 8px;
  }
  
  .right-column {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  
  .card {
    border: none;
  }
  
  .card-title {
    margin-bottom: 0;
  }
  
  .card-text {
    margin-bottom: 0.5rem;
  }
  
  h3,
  h4,
  h5 {
    color: #333;
    margin-top: 1.5rem;
  }
  
  ul {
    padding-left: 20px;
  }
  
  ul li {
    list-style-type: disc;
  }
  
  .mt-3 {
    margin-top: 1rem;
  }
  
  .mb-4 {
    margin-bottom: 1.5rem;
  }