/* Animación de desvanecimiento */
.page-fade-enter,
.page-fade-exit {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.page-fade-enter {
  opacity: 0;
}

.page-fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.page-fade-exit {
  opacity: 1;
}

.page-fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

/* Estilos para el contenedor de contenido */
.content-container {
  flex-grow: 1;
  position: relative;
}

/* Estilos para el paginador */
.paginator {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
}

.paginator span {
  color: white;
}

@media (max-width: 576px) {
  .paginator {
    position: static;
    margin-top: 20px; /* Add top margin */
    margin-bottom: 10px; /* Optional bottom margin */
    transform: none;
    justify-content: center;
  }
}